.root {
  z-index: 0;
}

.bg {}

.zoomable_bck {
  position: absolute;
  width: calc(var(--zoom) * 100%);
  height: calc(var(--zoom) * 100%);
  left: var(--left);
  top: var(--top);
}

.zoomable {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transform-origin: top left;
  transform: translate( calc(var(--left) * 1px), calc(var(--top) * 1px)) scale(var(--zoom));
  /*
  width: calc(var(--zoom) * 100%);
  height: calc(var(--zoom) * 100%);
  left: calc(var(--left) * 1px);
  top: calc(var(--top) * 1px);*/
}

.zoomable>* {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlay {
  z-index: 1 !important;
}

.zoomable {
  z-index: 0;
  pointer-events: none;
}

.zoomable .bg {
  z-index: 0;
}

.zoomable .img {
  z-index: 1;
}

.zoomable .activeAreas {
  z-index: 2;
  pointer-events: none;
}

.zoomable .overlay {
  z-index: 3 !important;
  pointer-events: none;
}

.zoomable .children {
  z-index: 4;
  pointer-events: none;
}

.pinchToZoom {
  pointer-events: all
}