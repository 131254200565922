.root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.root>* {
  flex: 0 0 auto
}

.tapIcon {
  width: 15rem;
  height: auto;
  margin-right: 5%;
  margin-top: 5%;
  animation: handAni 1s infinite alternate;
  -webkit-animation: handAni 1s infinite alternate;
}

.img {
  --x: 0;
  --y: 0;
  --angle: 0;
  --size: 260px;
  position: absolute;
  top: calc(100% * var(--y));
  left: calc(100% * var(--x));
  width: calc(var(--size) * 1.385);
  height: var(--size);
  background-size: contain;
  transform-origin: top left;
  transform: rotate(calc(var(--angle) * 1deg)) scale(.7);
}

.img img {
  border-radius: 50%;
  width: var(--size);
  height: var(--size);
  border: 12px solid var(--gold);
  background-color: var(--blue);
  transform: rotate(calc(var(--angle) * -1deg));
}

@keyframes handAni {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}

@-webkit-keyframes handAni {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}