.root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .6);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.62rem;
  animation: none;
}

.container {
  background-color: var(--white);
  color: var(--blue);
  display: flex;
  flex-direction: column;
  max-height: 60vh;
  position: absolute;
  border: 3px solid var(--blue);
  border-radius: 15px;
  overflow: hidden;
}

.animated .container {
  opacity: 0;
  animation: containerShow 1s ease-out 2s;
  animation-fill-mode: forwards
}

@keyframes containerShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: bgShow 1s ease-out 0s 1;
  animation-fill-mode: forwards;
  transform-origin: center center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg img {
  height: 120%;
}

@keyframes bgShow {
  0% {
    opacity: 0;
    transform: scale(.7);
  }
  20% {
    opacity: 1;
    transform: scale(.76);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.container h1 {
  text-align: center;
  flex: 0 0 auto;
  font-size: 1.5em;
}

.scrollableChildren {
  flex: 1 1 auto;
  overflow: auto;
  padding: 2rem;
  padding-bottom: 3rem;
  margin: 0 0.5rem 1rem 0;
  pointer-events: none;
}

.scrollableChildren>* {
  margin-bottom: 1rem;
  width: 100%;
  text-align: center;
  pointer-events: all;
  touch-action: none;
}

.scrollableChildren>*>* {
  max-width: 40vw;
}

.scrollableChildren img,
.scrollableChildren video {
  text-align: center;
  width: 100%;
}

.scrollableChildren .text {
  text-align: left;
}

.close {
  flex: 0 0 auto;
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  width: 4rem;
  cursor: pointer;
  border-radius: 5px;
}