.root {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}

.root>* {
  flex: 1 1 auto;
}

.root>*:last-child {
  flex: 0 0 auto;
}