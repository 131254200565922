.root {
  --progress: 0;
  --ratio: 0;
  white-space: pre-line;
  touch-action: none;
  overflow: hidden;
}

.scrollbar {
  box-sizing: border-box;
  top: 0;
  right: 0px;
  margin: 10px 5px;
  height: calc(100% - 20px);
  width: 10px;
  position: absolute;
  z-index: 5;
}

.root.hidden .scrollbar {
  display: none;
}

.scrollbar>* {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.scrollbar>.track {
  background: var(--blue-light);
}

.scrollbar>.thumb {
  height: calc(var(--ratio) * 100%);
  top: calc(var(--progress) * (100% - var(--ratio) * 100%));
  background: var(--blue);
}

.scrollbar>.thumb:hover {
  background: #555;
}