.root {
  position: relative;
  background-color: var(--white);
  overflow: hidden;
  width: 100%;
}

.bgimg {
  min-height: 100%;
  min-width: 100%;
  width: 1px;
}