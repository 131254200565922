.root {
  animation: none;
  z-index: 0;
}

.root img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loaded {
  opacity: 1;
}

.unactive {
  z-index: 0;
  opacity: 0;
  transition: opacity .1s .6s;
}

.active {
  z-index: 1;
  opacity: 1;
  transition: opacity .6s .1s;
}