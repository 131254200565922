.root {
  display: flex;
  flex-direction: row;
  gap: 1.5vh;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
}


.btn {
  background-color: var(--blue-light);
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 3.5vh;
  height: 3.5vh;
  width: 3.5vh;
  border-radius: .5vh;

}

.btn.selected {

  animation-name: selectedAnimation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes selectedAnimation {
  0% {
    background-color: var(--blue-light);
  }

  50% {
    background-color: var(--blue-middle);
  }

  100% {
    background-color: var(--blue-light);
  }
}