.root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .6);
  z-index: 99;
}

.root .overlay {
  min-width: 100%;
  min-height: 100%;
  width: 1px;
}

.root:global(.right.admin) {
  display: none
}

.admiLogin {
  position: absolute;
  bottom: 35%;
  left: 50%;
  transform: translate(-50%, 50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top {
  position: relative;
  background-color: var(--white);
  padding: 1vh;
  width: 19vw;
  border: 2px solid var(--blue);
  border-radius: .5vh;
  margin-bottom: 3vh;
  color: var(--blue);
  text-align: center;
}

.top .close {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 3rem;
  height: 3rem;
  font-size: 2rem;
  background-size: contain;
  background-position: top right;
  cursor: pointer;
}

.top h1 {
  display: block;
  font-size: 1rem;
}

.top h2 {
  display: block;
  font-size: 1rem;
  font-weight: normal;
}

.bottom {
  position: relative;
  background-color: var(--blue-light);
  padding: 1vh;
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
  width: 14vw;
  gap: 1.5vh;
  border: 2px solid var(--blue);
  border-radius: .5vh;
}

.btn {
  flex: 1 1 auto;
  border-radius: 0.5vh;
  background-color: var(--white);
  color: var(--blue);
  width: 3vw;
  height: 3.5vh;
  line-height: 3.5vh;
  font-size: 2vh;
  text-align: center;
  cursor: pointer;
}

.btn>img {
  max-width: 80%;
  max-height: 80%;
  margin-top: .4rem;
}