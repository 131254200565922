.details {
  position: absolute;
  bottom: calc(var(--height) * .5);
  pointer-events: none;
  opacity: 0;
  width: 100%;
  height: 0;
  transition: height .2s, opacity .4s;
}

.details.active {
  opacity: 1;
  pointer-events: all;
  height: var(--height);
  transition: height .5s, opacity .2s .2s;
}

.stripe {
  width: 100%;
  background-color: var(--blue);
  position: absolute;
  bottom: -3px;
  height: 100%;
  border-radius: .5vh;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.openedContent {
  opacity: 0;
  transition: opacity .5s;
  position: absolute;
  left: calc(-1 * var(--height));
  bottom: calc(-10px + var(--height));
}

:global(.right .legend) .openedContent {
  left: unset;
  right: calc(-1 * var(--height));
}

:global(.right .help) .openedContent {
  left: unset;
  right: calc(-3.8 * var(--height));
}

.active .openedContent {
  opacity: 1;
  transition: opacity .5s .2s;
}

.dynamic {
  --maxWidth: 700px;
  --maxHeight: 500px;
  background-color: var(--white);
  border-radius: 10px;
  border: 6px solid var(--blue);
  overflow: hidden;
  color: var(--blue);
  font-size: 1rem;
  width: var(--maxWidth);
  line-height: 1.25;
  padding: 0 1rem;
  font-size: 1.2rem;
  margin: 0rem;
  position: relative;
}

.scrollable {
  margin: 1rem;
  width: calc(100% - 1rem);
  padding-right: 1rem;
  max-height: var(--maxHeight);
  overflow: auto;
  padding-bottom: 1rem;
}

.scrollable>:first-child {}

.dynamic h1 {
  text-align: center;
  font-size: 1.25em;
}

.dynamic p {
  text-align: left;
  font-size: 1.2em;
}

.dynamic>*>img {
  width: calc(var(--maxWidth) - 6rem);
  pointer-events: none;
}


/*dynamic elements from text files*/

.dynamic>*>*>* {
  width: 100%;
  pointer-events: none;
}