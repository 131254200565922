.root {
  position: relative;
  --height: 8vh;
  background-color: var(--gold);
  height: var(--height);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: calc(var(--height) * .38);
  padding: 0 calc(var(--height) * .44);
}

.root:global(.right) {
  flex-direction: row-reverse;
}

.root .btn {
  display: inline-block;
  background-color: var(--blue);
  width: calc(var(--height) * .55);
  height: calc(var(--height) * .55);
  line-height: calc(var(--height) * .55);
  border-radius: .4vw;
  text-align: center;
  font-size: 3.5rem;
  position: relative;
}

.root .btn>img {
  cursor: pointer;
  max-width: 90%;
  max-height: 90%;
  margin-top: 6%;
}

.root .btn .langDiv {
  cursor: pointer;
}

.languageSelector>:last-child {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  overflow: hidden;
  left: 0;
}

.languageSelectorContainer {
  background-color: var(--blue);
  border-radius: 15px;
  margin-bottom: -.4rem;
}

.root .adminBtn {
  width: 4rem;
  height: 4rem;
  line-height: 3rem;
  position: absolute;
  right: 2rem;
  bottom: 2rem;
}

.menu {
  background-color: var(--blue);
  text-align: left;
  font-size: 1.8rem;
  line-height: 2;
  border-radius: 15px;
  min-width: 300px;
  padding-bottom: 2rem;
  ;
}

.menu h1 {
  font-size: inherit;
  padding: 1rem
}

.menuEntry {
  background-color: var(--blue);
  color: var(--white);
  padding: .9rem 1rem;
  line-height: 1.2;
}

.menuEntry_active {
  background-color: var(--white);
  color: var(--blue);
}

.menu_close {
  position: absolute;
  right: 1rem;
  top: 2rem;
  width: 3rem;
}

.blue_close {
  position: absolute;
  left: .5rem;
  bottom: .5rem;
  width: 3rem !important;
  cursor: pointer;
  pointer-events: all !important;
}

.active .blue_close {}


/*
.active.languageSelector {
  height: 25vh;
  opacity: 1;
  transition: height 1s, opacity 0.5s .1s;
}

.unactive.languageSelector {
  height: 0vh;
  opacity: 0;
  transition: height .5s, opacity 0.4s;
}

*/


/*
.active {
  height: 25vh;
  opacity: 1;
  transition: height 1s, opacity 0.5s .1s;
}

.unactive {
  height: 0vh;
  opacity: 0;
  transition: height .5s, opacity 0.4s;
  pointer-events: none;
}




*/

.root .label {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 0;
  color: var(--blue);
  font-size: 0.8rem;
  display: none;
}