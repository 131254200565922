.root {
  pointer-events: none;
}

.title {
  position: absolute;
  top: 0;
  left: 5rem;
  background-color: var(--gold);
  padding: 3rem 2.5rem;
  min-width: 25rem;
  line-height: 1.1;
  color: var(--blue);
  font-size: 2.5rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.back {
  position: absolute;
  bottom: 3rem;
  width: 150px;
  height: 56px;
  background-color: var(--gold);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  pointer-events: all;
}

.back>img {
  max-width: 90%;
  max-height: 90%;
}