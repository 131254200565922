.root {
  opacity: 1;
  pointer-events: none;
}

.root>* {
  position: absolute;
  top: 0;
  left: 0;
}

.title {
  background-color: var(--gold);
  left: 5rem;
  padding: 3rem 2.5rem;
  min-width: 25rem;
  line-height: 1.1;
  color: var(--blue);
  font-size: 2.5rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.progress {
  pointer-events: all;
  width: 30rem;
  top: unset;
  left: 15rem;
  bottom: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.progress.left {
  left: unset;
  right: 10rem;
}

.progress * {
  pointer-events: none;
}

.label {
  flex: 0 0 auto;
  background-color: var(--gold);
  border-radius: 12px;
  color: var(--blue);
  padding: .5rem 3rem 0.7rem 3rem;
  font-size: 2.2rem;
  font-weight: bold;
}

.progressBorder {
  position: relative;
  flex: 0 0 auto;
  --progress: .2;
  border-radius: 1rem;
  border: 5px solid var(--blue);
  height: 2rem;
  width: 100%;
  background-color: var(--white);
  margin-bottom: 1rem;
}

.thumb {
  position: absolute;
  width: 1.9rem;
  height: 1.9rem;
  top: -4px;
  left: calc((100% - 1.9rem + 1px) * var(--progress) - 1px);
  border-radius: 50%;
  background-color: var(--blue);
}