.root {
  background-color: rgba(0, 0, 0, 1);

  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
}


.root.rotated {
  transform: rotate(180deg);
}


.root>* {
  flex: 1 1 0;
}