.root {
  pointer-events: none;
}

.subentry {
  --x: 0.5;
  --y: 0.5;
  --size: 150px;
  position: absolute;
  top: calc(var(--y) * 100% - var(--size) / 2);
  left: calc(var(--x) * 100% - var(--size) / 2);
  width: var(--size);
  height: var(--size);
  background-color: var(--white);
  color: var(--blue);
  text-align: center;
  font-size: 1.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  cursor: pointer;
  transform-origin: center;
  transform: scale(calc(1 / var(--zoom)));
  pointer-events: all;
}

.subsubentry {
  --size: 200px;
  background-color: var(--white-semi);
  font-weight: bold;
  font-size: 2rem;
  color: var(--black)
}