* {
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html,
body,
#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  --white: #ffffff;
  --white-semi: #ffffff9a;
  --gold: rgba(237, 198, 127, 1);
  --blue: rgba(0, 65, 99, 1);
  --blue-middle: rgba(0, 65, 99, .5);
  --blue-light: rgba(153, 179, 193, 1);
  --black: #000000
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0 !important;
  }
  to {
    opacity: 1 !important;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

*:not(.static) {
  animation: 0.5s ease-out 0s 1 fadeIn;
}

::-webkit-scrollbar {
  width: 10px;
}


/* Track */

::-webkit-scrollbar-track {
  background: var(--blue-light);
  border-radius: 5px;
}


/* Handle */

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: var(--blue);
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

a {
  text-decoration: none;
  color: inherit;
}

.tmp {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  opacity: .1;
  overflow: hidden;
  z-index: 99999;
}

.tmp>img {
  width: 100%;
  height: 100%;
  /*
  width: auto;
  height:auto;
  */
  width: auto;
}

.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: none;
  pointer-events: none;
}